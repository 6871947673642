'use client'
import React, { FC, ReactNode } from 'react'
import { CacheProvider } from '@chakra-ui/next-js'
import { ChakraProvider, Text } from '@chakra-ui/react'
import { customTheme } from '@/styles/theme'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { API_KEY } from '@/constants'

interface AppProvidersProps {
  children: ReactNode
}

const AppProviders: FC<AppProvidersProps> = ({ children }) => {
  if (!API_KEY) {
    return <Text color="red">NEXT_PUBLIC_GOOGLE_MAPS_API_KEY not found</Text>
  }

  return (
    <CacheProvider>
      <ChakraProvider resetCSS theme={customTheme}>
        <ToastContainer
          className="Toastify__toast-container"
          hideProgressBar
          pauseOnHover={false}
          draggable={false}
        />
        {children}
      </ChakraProvider>
    </CacheProvider>
  )
}

export default AppProviders
