import { defineStyleConfig } from '@chakra-ui/react'

export const tooltipTheme = defineStyleConfig({
  variants: {
    solid: () => ({
      minW: 120,
      px: 4,
      borderColor: 'primary',
      borderWidth: 1,
      rounded: 'lg',
      fontSize: 'md',
      textAlign: 'center',
      color: 'primary.700',
      bg: 'background.surface',
      WebkitTapHighlightColor: 'transparent',
      _hover: {
        bg: 'secondary'
      }
    })
  }
})
