import localFont from 'next/font/local'

const calibre = localFont({
  src: [
    {
      path: '../fonts/calibre-regular.woff2',
      weight: '400',
      style: 'normal'
    },
    {
      path: '../fonts/calibre-regular.woff2',
      weight: '500',
      style: 'normal'
    },
    {
      path: '../fonts/calibre-medium.woff2',
      weight: '600',
      style: 'medium'
    },
    {
      path: '../fonts/calibre-bold.woff2',
      weight: '700',
      style: 'bold'
    },
    {
      path: '../fonts/calibre-bold.woff2',
      weight: '800',
      style: 'bold'
    },
    {
      path: '../fonts/calibre-bold.woff2',
      weight: '900',
      style: 'bold'
    }
  ]
})

const financierDisplay = localFont({
  src: [
    {
      path: '../fonts/financier-display-regular.woff2',
      weight: '400',
      style: 'normal'
    },
    {
      path: '../fonts/financier-display-regular.woff2',
      weight: '500',
      style: 'normal'
    },
    {
      path: '../fonts/financier-display-medium.woff2',
      weight: '600',
      style: 'medium'
    },
    {
      path: '../fonts/financier-display-semibold.woff2',
      weight: '700',
      style: 'semibold'
    },
    {
      path: '../fonts/financier-display-semibold.woff2',
      weight: '800',
      style: 'semibold'
    },
    {
      path: '../fonts/financier-display-semibold.woff2',
      weight: '900',
      style: 'semibold'
    }
  ]
})

export const fonts = {
  heading: financierDisplay.style.fontFamily,
  body: calibre.style.fontFamily
}
