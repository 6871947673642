import { defineStyleConfig } from '@chakra-ui/react'

export const buttonTheme = defineStyleConfig({
  baseStyle: {
    WebkitTapHighlightColor: 'transparent'
  },
  defaultProps: {
    colorScheme: 'primary'
  },
  sizes: {
    sm: {
      fontSize: 'sm',
      fontWeight: 'normal'
    }
  },
  variants: {
    solid: () => ({
      minW: 120,
      rounded: 'full',
      color: 'primary.700',
      bg: 'background.surface',
      WebkitTapHighlightColor: 'transparent',
      _hover: {
        bg: 'secondary'
      },
      _active: {
        bg: 'secondary'
      }
    }),

    outline: () => ({
      minW: 120,
      rounded: 'full',
      color: 'primary.700',
      bg: 'transparent',
      borderColor: 'primary.700',
      borderWidth: 1,
      WebkitTapHighlightColor: 'transparent'
    }),

    ghost: ({ colorScheme }) => ({
      shadow: 'none',
      textDecoration: 'underline',
      _hover: {
        bg: 'transparent'
      },
      _active: { color: `${colorScheme}.500`, bg: 'transparent' }
    }),

    link: () => ({
      textDecoration: 'underline'
    })
  }
})
