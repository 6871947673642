export const primaryColorScheme = {
  50: '#c4e9df',
  100: '#85dda8',
  200: '#17E88F',
  300: '#27b177',
  400: '#25735e',
  500: '#243944',
  600: '#003F2D',
  700: '#012A2D',
  800: '#01262d',
  900: '#01211d'
}

export const brandColors = {
  cbreGreen: '#003F2D',
  midnight: '#032842',
  midnightTint: '#778F9C',
  accentGreen: '#17E88F',
  sage: '#538184',
  sageTint: '#96B3B6',
  darkGreen: '#012A2D',
  celadon: '#80BBAD',
  celadonTint: '#C0D4CB',
  darkGrey: '#435254',
  wheat: '#DBD99A',
  wheatTint: '#EFECD2',
  lightGrey: '#CAD1D3',
  cement: '#7F8480',
  cementTint: '#CBCDCB',
  negativeRed: '#AD2A2A'
}

export const semanticTokens = {
  colors: {
    primary: {
      default: 'primary.600'
    }
  }
}

export const colors = {
  primary: primaryColorScheme,
  secondary: brandColors.accentGreen,
  text: {
    default: primaryColorScheme[700],
    invert: '#fff'
  },
  background: {
    default: '#fff',
    surface: '#f6f8f8'
  },
  status: {
    error: brandColors.negativeRed,
    success: 'green.400',
    warning: 'orange.400'
  },
  ...brandColors
}
