import { MapLocation } from '@/core/real-estate/typings'

export const API_KEY = process.env['NEXT_PUBLIC_GOOGLE_MAPS_API_KEY']

export const DEFAULT_ZOOM = 4
export const MAP_DEBOUNCE = 300

export const SEARCH_ITEM_SIZE = '280px'

export const NAVBAR_HEIGHT = '6vh'
export const SEARCHBAR_HEIGHT = '10vh'
export const CONTENT_HEIGHT = '84vh'

export const MEXICO_CITY_COORDINATES = [-99.13337548934115, 19.437235103232915] as MapLocation
export const EXCEL_ROW_COLOR = {
  type: 'pattern',
  pattern: 'solid',
  bgColor: { argb: '000047AB' },
  fgColor: { argb: '000047AB' }
}
export const EXCEL_CELL_COLOR = {
  type: 'pattern' as const,
  pattern: 'solid',
  fgColor: { argb: 'FFD3D3D3' }
}
export const EXCEL_HEADER_ALIGNMENT = {
  horizontal: 'center',
  vertical: 'middle'
}

export const EXCEL_ROW_ALIGNMENT = {
  vertical: 'middle'
}

export const EXCEL_HEADER_FONT = {
  bold: true,
  color: {
    argb: 'FFFFFFFF'
  },
  size: 14
}

export const EXCEL_DEFAULT_ROW_HEIGHT = 22
export const EXCEL_DEFAULT_HEADER_HEIGHT = 30

export const EXCEL_EVEN_ROW_FILL = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'FFF8F8F8' }
}

export const AREA_MAX_DISTANCE = 100000
